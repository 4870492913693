import React from 'react';
import {Helmet} from "react-helmet";
import Layout from "../../components/layout";
import { Title, Twitter, Testonimal } from "../../components/Globals";
import { Link } from 'gatsby';
import Iframe from 'react-iframe';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
// import 'react-awesome-slider/src/styles.js';
const SdfcIntegrationServicesPage = () => {

    const [services] = React.useState([
        {
          id: 1,
          title: 'Salesforce Partner',
          desc: 'Trusted Salesforce Partner with 115+ certified Salesforce professionals and a global presence.',
          image: 'salesforce-partner.png',
          alt:'Salesforce integration India USA UK Poland',
          imagetitle:'Trusted Salesforce Partner With Global Presence ',
          url: {
           
          }
        },
        {
          id: 2,
          title: 'Industry & Domain Expertise',
          desc: 'We understand industry and domain specific challenges and infuse strategic guidance & best practices in our solutions. ',
          image: 'sfdc-integration-services.png',
          alt:'Salesforce integration partners india usa',
          imagetitle:'Deep Industry & Domain Expertise',
          url: {
          }
        },
        {
          id: 3,
          title: 'Trusted Partnership',
          desc: 'Our agile partnership can help you seamlessly navigate intricate business transformations. ',
          image: 'salesforce-sap-integration.png',
          alt:'ERP Salesforce CRM integration',
          imagetitle:'Partner With Us',
          url: {
          }
        },
        {
          id: 4,
          title: 'Scalability',
          desc: 'We offer scalable and cross-functional solutions being part of an integral part of a multi-capabilty group.',
          image: 'salesforce-integration-companies-mumbai.png',
          alt:'salesforce 360 integration ',
          imagetitle:'Scalable Solutions',
          url: {
          }
        },
     
      ]);

    return (
          <Layout>
            <Helmet>
        <title>Salesforce Integration Services | Cymetrix Salesforce Integrators</title>
        <meta name="description" content="Cymetrix Software provides Salesforce Integration Services offering solutions to integrate Salesforce with different endpoints including legacy, custom and packaged apps."/>
      <meta name="keywords" content="cymetrix,Salesforce integration services, sfdc integration, Salesforce integrators, Cymetrix, cymetrixsoft, Cymetrixsoftware"/>
             <link rel="canonical" href="https://www.cymetrixsoft.com/services/sdfc-integration-services/" />
      </Helmet>
              <div className="salesforce-implementation-services-background h-screen flex flex-col w-full">
        <p className="block text-base xl:text-2xl xl:px-48 xl:mt-48 md:mt-16 sm:mt-24 lg:text-4xl md:text-3xl sm:text-3xl sma:text-xl
        text-white text-uppercase text-center">
         GET SEAMLESS CONNECTIVITY WITH SALESFORCE INTEGRATION
       
          </p>
        <p className="block text-base xl:text-2xl md:mt-16 lg:text-lg md:text-base sm:text-base sma:text-base
        text-white text-uppercase text-center">
          Improve your operational environment by integrating different applications with your Salesforce platform.
        </p>
      </div>
              <div >
                  <div className="flex flex-col lg:flex-row md:items-center max-w-screen-xl ml-32 py-20 md:py-24 justify-center">
                      <div className="sma:mx-3 relative mt-12 lg:mt-0 flex flex-col justify-center">
                          <div className="flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none">
                              <img
                                  className="h-72 w-72"
                                  src={require('./../../images/services/saleforceApiImg.png')}
                                  alt="Hero"
                                  />
                          </div>
                      </div>
                      <div className="sma:mx-3 relative lg:w-8/12 flex-shrink-0 lg:text-lef justify-center md:ml-10">
                          <h1 className="font-black text-3xl leading-snug max-w-3xl text-hover font-serif">
                          Salesforce Integration Services
                          </h1><br />
                          <hr className="border-solid border-black mb-5" style={{borderWidth: 1}}/>
                          <p className="font-hairline">As a part of Cymetrix's <strong className="font-bold">Salesforce Integration Services</strong> , we do complete end to end requirement analysis, design integration roadmap, integration solution development, implementation and post-delivery support and maintenance. Create an integrated SFDC environment with Cymetrix, one of the top and trusted Salesforce Integrators <br/> <br/>

                        <div className="bg-transparent hover:border-blue-500 text-gray-700 text-base font-thin w-64 py-3 px-2 border border-yellow-500 hover:border-transparent rounded mb-8 uppercase max-w-sm mt-5 text-center">
                            <Link target="_blank"
                                to="/contact-us">Contact Cymetrix</Link>
                        </div>

                         
                          </p>
                      </div>
                  </div>
  
                  
              </div>
       
              <div className="product-background w-full flex flex-col items-center" title="">
                <div className="max-w-6xl mx-4 my-16">
                    <h1 className="text-white text-3xl text-left sma-xl font-thin">Our Salesforce Integration Practices</h1>
                    <div class="flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-14">
                    <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Salesforce API Integration</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Seamlessly connect Salesforce with your existing systems through robust API integration. Effortlessly exchange data, automate processes, and enhance your customer relationship management.</div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Salesforce Integration Using Mulesoft</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Elevate your Salesforce capabilities with Mulesoft integration. Achieve unparalleled connectivity, optimize data flow, and empower your organization with advanced integration solutions.</div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Salesforce AppExchange Integration</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Leverage the power of Salesforce's extensive ecosystem by integrating with AppExchange. Extend your Salesforce functionality with a world of customizable apps and services.</div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">Salesforce Custom Integration</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Tailor Salesforce to your unique needs through custom integration services. Unlock the full potential of Salesforce by aligning it precisely with your business processes and objectives.</div>
                            </div>
                        </div>


                    </div>
                    <div className="flex flex-row justify-center">
                        <div className="bg-transparent hover:border-blue-500 text-base font-thin w-64 py-3 px-2 border border-yellow-500 hover:border-transparent rounded mb-8 uppercase max-w-sm mt-5 text-center text-white">
                            <Link target="_blank"
                                to="/contact-us">Contact Cymetrix</Link>
                        </div>
                    </div>
                </div>
            </div>


        <div className="section-title text-center m-8">
          <span className={`font-normal border-white border-none relative uppercase text-gray-800 sma:text-xl text-4xl  sm:text-xl md:text-4xl`}>Diverse Salesforce Growth Possibilities
          </span>
        </div>

        <div className="mx-0 flex justify-center">
          <div className="relative max-w-6xl mx-auto  mb-10 sma:mx-3">
            <div class="flex flex-col sm:flex-row sm:-mx-2 mt-10">

              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">ERP Integration like SAP, NetSuite and more</div>
                </div>
              </div>
              
              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Marketing Automation Integration</div>
                </div>
              </div>

              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Database Integration </div>
                </div>
              </div>

            </div>
            <div class="flex flex-col sm:flex-row sm:-mx-2 mt-10">

              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Accounting Integration</div>
                </div>
              </div>
              
              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Social Media Integration </div>
                </div>
              </div>

              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">E-Commerce Integration</div>
                </div>
              </div>

            </div>
            <div class="flex flex-col sm:flex-row sm:-mx-2 mt-10">

              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Collaborative System Integration</div>
                </div>
              </div>
              
              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Email Integration </div>
                </div>
              </div>

              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Other Application Integrations</div>
                </div>
              </div>

            </div>
 

          </div>
        </div>


            <div className="relative max-w-6xl mx-auto">
          <div className="flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24">
              <div className="sma:mx-3 relative lg:w-6/12 lg:pr-12 flex-shrink-0 lg:text-left">
              <h1 className="font-black text-base leading-snug max-w-3xl text-hover font-serif">
              Revolutionize Connectivity: Empower Transformation with MuleSoft 														
              </h1><br />
              <p className="font-hairline text-justify">Seamlessly connect and enhance your Salesforce experience with expert <Link to="/services/mulesoft-consulting/" class="underline text-blue-700"> MuleSoft consulting</Link> Elevate your CRM capabilities with streamlined integration solutions.				
              </p><br/>
              </div>

              <div className="sma:mx-3 relative mt-12 lg:mt-0 flex flex-col justify-center">
              <div className="flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none">
                  <img
                      className="border-8 border-solid"
                      src={require('./../../images/services/salesforce-integration-partner-services.png')}
                      alt="Salesforce Mulesoft Integration Services"
                      title="Salesforce MuleSoft"
                      />
                </div>
              </div>
          </div>
        </div>


        <div className="relative max-w-6xl mx-auto">
          <div className="flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24">
          <div className="sma:mx-3 relative mt-12 lg:mt-0 flex flex-col justify-center">
              <div className="flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none">
                  <img
                      className="border-8 border-solid"
                      src={require('./../../images/services/salesforce-360-integration-india.png')}
                      alt="Salesforce Customer 360"
                      title="Salesforce MuleSoft"
                      />
                </div>
              </div>
              <div className="sma:mx-3 relative lg:w-6/12 lg:pr-12 flex-shrink-0 lg:text-left">
              <h1 className="font-black text-base leading-snug max-w-3xl text-hover font-serif">
              Stop guessing, start knowing: Unlock the customer magic with a 360° view! 														
              </h1><br />
              <p className="font-hairline text-justify">With data from various touchpoints and channels, you can create a comprehensive profile of each customer, enabling personalized interactions and targeted marketing strategies. From anticipating needs to resolving issues swiftly, a 360-degree view empowers you to deliver exceptional customer experiences, fostering loyalty and driving business growth. Embrace the holistic approach, and watch your customer relationships flourish like never before.			
              </p><br/>
              <p className="font-hairline text-justify"><Link to="/contact-us" class="underline text-blue-700"> Connect With Us</Link>
              </p><br/>
              </div>


          </div>
        </div>


        <div className="relative max-w-6xl mx-auto">
          <div className="flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24">
              <div className="sma:mx-3 relative lg:w-6/12 lg:pr-12 flex-shrink-0 lg:text-left">
              <h1 className="font-black text-base leading-snug max-w-3xl text-hover font-serif">
              Power Your Growth Journey with On-Demand Salesforce Talent 														
              </h1><br />
              <p className="font-hairline text-justify">Whether you need to rapidly expand your operations or tackle complex projects, our on-demand model ensures that the right Salesforce resources are available when you need them.				
              </p><br/>
              <p className="font-hairline text-justify"><Link to="/services/staff-augmentation-services" class="underline text-blue-700">Staff Augmentation Services</Link>
              </p><br/>
              </div>

              <div className="sma:mx-3 relative mt-12 lg:mt-0 flex flex-col justify-center">
              <div className="flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none">
                  <img
                      className="border-8 border-solid"
                      src={require('./../../images/services/salesforce-integration-partner-services.png')}
                      alt="Salesforce Mulesoft Integration Services"
                      title="Salesforce MuleSoft"
                      />
                </div>

              </div>
          </div>
        </div>



        <div className="service-background w-full flex flex-col items-center ">
          <div className="max-w-6xl mx-4 sma:mb-4">
              <Title title="Why Choose Cymetrix for Salesforce Integration Services" classValue={'text-white sma:text-xl text-4xl'} />
              <div className="flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-14">
                {
                  services.map(service => {
                      return <div className="mt-4 md:w-1/2 lg:w-1/4" key={service.id}>
                          <div className="bg-white p-8 h-full border-b-4 border-pink-500 rounded-lg hover:border-hover flex flex-col items-center sm:mx-2 sm:p-3 md:p-8">
                              <div className="text-indigo-700 w-32 flex items-center justify-center h-32 p-2 rounded-full">
                              <img src={require(`../../images/services/${service.image}`)} title={service.imagetitle} alt={service.alt} className="w-24 h-24"/>
                              </div>
                              <h1 className="mt-5 text-center">{service.title}</h1>
                              <p className="mt-5 text-sm text-gray-600 text-center">{service.desc}</p>
                              <Link to={service.url.url} className="mt-5 text-hover text-lg text-center">{service.url.title}</Link>
                          </div>
                      </div>
                  })
                }
              </div>
          </div>
        </div>


        <div className="flex flex-col mx-auto max-w-6xl sma:my-3">
                <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1 sma:my-2 my-5"/>
                <h1 className="mt-5 text-xl font-semibold">Case Studies</h1>
                <div className="flex sma:flex-col sm:flex-row">
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../images/services/salesforce-consulting-services-usa-pharma.png')} alt="Salesforce consulting partners pharma case study | Cymterix Software" title="" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://www.cymetrixsoft.com/customer-stories/b2b-commerce-cloud-pharma/" >Streamlining global wholesale operations with Salesforce & SAP for a pharmaceutical company</Link></h1>
                          
                        </div>
                    </div>
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../images/services/salesforce-partner-india-fintech.png')} alt="Salesforce consulting fintech company | Cymetrix Software" title="" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://www.cymetrixsoft.com/customer-stories/fintech-community-cloud/">Revolutionizing bill discounting with a self-serving co-lending fintech platform</Link></h1>
                            
                        </div>
                    </div>
                </div>
            </div>




        <div className="flex flex-col mx-auto max-w-6xl sma:my-3">
                <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1 sma:my-2 my-5"/>
                <h1 className="mt-5 text-xl font-semibold">From Our Blogs</h1>
                <div className="flex sma:flex-col sm:flex-row">
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../images/services/salesforce-partners-license-types.png')} alt="Salesforce partners for licencing | Cymterix Software" title="Salesforce license types" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://blogs.cymetrixsoft.com/2023/11/02/understanding-salesforce-license-types-for-optimization-and-cost-reduction/" >Understanding Salesforce License Types For Optimization and Cost Reduction</Link></h1>
                            <p className="text-lg">Read about the core attributes of Salesforce.com licensing agreements.</p>
                        </div>
                    </div>
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../images/services/salesforce-einstien-gpt-ai.png')} alt="Salesforce Einstien GPT | Cymetrix Software" title="Why Salesforce generative AI is the next big thing?" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://blogs.cymetrixsoft.com/2023/11/07/salesforce-einstein-gpt-why-generative-ai-is-the-next-big-thing/">Salesforce Einstein GPT: Why Generative AI is the next big thing?</Link></h1>
                            <p className="text-lg">Read about how Salesforce Einstien is revolutionizing the way CRM works.</p>
                        </div>
                    </div>
                </div>
            </div>


        <Testonimal />

        <div className="flex flex-col mx-auto items-center rounded-lg pb-10">
                    <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1"/>
                    <div className="px-6 py-4 bg-white  text-gray-700">
                        <h4 class="text-lg" style={{color: '#1e407b'}}><b>FAQs:</b></h4>
                        <br/>
                        <h4 class="text-lg"><b>How can Salesforce integration benefit my business?</b></h4>
                        <p> By integrating Salesforce with other systems, businesses can centralize data, automate processes, and gain valuable insights, leading to improved decision-making and overall operational efficiency.</p>
                        <br/>
                        <h4 class="text-lg"><b>As a Salesforce Partner, does Cymetrix offer on-demand Salesforce talent?</b></h4>
                        <p> Whether you need to rapidly expand your operations or tackle complex projects, our on-demand model ensures that the right Salesforce resources are available when you need them.</p>
                        <br/>
                        <h4 class="text-lg"><b>In which locations does Cymetrix provide Salesforce consulting and integration services?</b></h4>
                        <p> We can provide near-shore consultation and offshore delivery powered by our network of offices in USA, UK, Poland, Japan and India.</p>

                    </div>
                </div>

          </Layout>
      );
  }
  
  export default SdfcIntegrationServicesPage;